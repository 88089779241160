/**
 * Object for apiConfig
 *
 * @format
 * @property {string} base api url
 * @property {string} domain url domain
 */

const analyticsConfig = {
  bugsnag: {
    apiKey: '6823dbad8f4afab5fbf72fe7c11670ee',
    releaseStage: 'dev',
    appVersion: __VERSION__,
    consoleBreadcrumbsEnabled: false,
    maxEvents: 50,
    notifyReleaseStages: ['dev'],
    autoNotify: false
  },
};

export { analyticsConfig };
