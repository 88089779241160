/**
 * Object for pusherKey
 *
 * @format
 * @property {string} key pusher 
 */

const pusherKey = {
  key: 'a677d43607bf8b88ee2c'
};

export {
  pusherKey
};
