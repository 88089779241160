/** @format */

import {
  PLATFORM
} from 'aurelia-pal';
import {
  inject
} from 'aurelia-framework';
import {
  Router
} from 'aurelia-router';
import eventService, {
  EventsList
} from 'services/eventService';

class RouterPostRenderEvent {
  run(navInstr, next) {
    if (navInstr && navInstr.config) {
      eventService.publish(EventsList.AppRouterChanged, navInstr.config.name);
    }
    return next();
  }
}

/**
 * Router config class for aurelia-router
 */
@inject(Router)
class RouterConfig {
  /**
   * @param {Router} router object from aurelia-router
   */
  constructor(router) {
    this.router = router;
  }

  /**
   * configure callback called from aurelia plugin
   */
  configure(steps) {
    let appRouterConfig = config => {
      config.title = '';

      let navsPath = 'components/elements/navs';
      let componentsPath = 'components';
      let pagesPath = 'pages';

      //helper fn - builds path to look for element or page
      let getModulePath = (path, isComponent) => {
        let paths = path.split('/');
        let name = paths.splice(-1, 1); //take last one, remove from array
        let moduleBasePath = isComponent ? componentsPath : pagesPath; //device where to look

        paths = paths.length > 0 ? paths.join('/') + '/' : ''; //re-build passed path
        return PLATFORM.moduleName(`${moduleBasePath}/${paths}${name}/${name}`); //build full path
      };

      for (let step in steps) {
        config.addPipelineStep(step, steps[step]);
      }

      config.addPostRenderStep(RouterPostRenderEvent);

      config.map([
        {
          route: 'dashboard',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/dashboard/dashboard')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Dashboard',
          name: 'dashboard',
          permission: 'modules.dashboard',
          icon: 'fi-dashboard'
        },
        {
          route: ['', 'track'],
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar-map/nav-bar-map')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/track/track')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Map',
          name: 'track',
          icon: 'fi-world'
        },
        {
          route: 'messaging',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/messaging/messaging')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Messaging',
          permission: 'modules.messaging',
          experimental: true,
          name: 'messaging',
          icon: 'fi-sms'
        },
        {
          route: 'reports',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/reports/reports')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Reports',
          permission: 'modules.reports',
          name: 'reports',
          icon: 'fi-file-text'
        },
        {
          route: 'alerts',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/alerts/alerts')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Alerts',
          permission: 'modules.alerts',
          name: 'alerts',
          icon: 'fi-alert-circle'
        },
        {
          route: 'management',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/management/management')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Management',
          permission: 'modules.management',
          name: 'management',
          icon: 'fi-folder'
        },
        {
          route: 'cctv',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/cctv/cctv')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'CCTV',
          name: 'cctv',
          permission: 'modules.cctv',
          // experimental: true,
          icon: 'fi-video'
        },
        {
          route: 'files',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/files/files')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Files',
          name: 'files',
          permission: 'modules.tacho',
          //experimental: true,
          icon: 'fi-download-cloud'
        },
        {
          route: 'admin',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/admin/admin')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Administration',
          permission: 'modules.administration',
          name: 'admin',
          icon: 'fi-settings2'
        },
        {
          route: 'provisioning',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/provision/provision')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Provisioning',
          name: 'provisioning',
          permission: 'modules.provisioning',
          icon: 'fi-package'
        },
        {
          route: 'diagnostics',
          viewPorts: {
            navbar: {
              moduleId: PLATFORM.moduleName('components/elements/navs/nav-bar/nav-bar')
            },
            main: {
              moduleId: PLATFORM.moduleName('pages/diagnostics/diagnostics')
            }
          },
          nav: true,
          sidebar: true,
          auth: true,
          title: 'Diagnostics',
          name: 'diagnostics',
          permission: 'modules.provisioning.super',
          icon: 'fi-activity'
        },
        {
          route: 'logout',
          viewPorts: {
            main: {
              moduleId: PLATFORM.moduleName('pages/logout/logout')
            }
          },
          nav: false,
          sidebar: false,
          auth: false,
          title: 'Sign Out',
          name: 'logout',
        }
      ]);
      config.mapUnknownRoutes(( /*instruction*/) => {
        // console.log('unkownRoute',instruction)
        setTimeout(() => {
          this.router.navigateToRoute('track');
        }, 0);
      });

      return config;
    };

    this.router.configure(appRouterConfig);
  }
}

export { RouterConfig }
