/**
 * Object for ie18n aurelia-i18n plugin config
 *
 * @format
 * @see https://github.com/aurelia/i18n
 * @property {boolean} debug
 * @property {string} fallbackLng
 * @property {string} lng
 * @property {Array[]} attributes
 * @property {object} backEnd
 */

import {
  apiConfig
} from 'envconfigs/apiConfig';
const i18nConfig = {
  debug: false,
  fallbackLng: 'en',
  attributes: ['t', 'i18n'],
  ns: ['common'],
  defaultNS: 'common',
  fallbackNS: 'common',
  backend: {
    loadPath: `${apiConfig.url}api/localization/translations?culture={{lng}}&module={{ns}}`
  }
};

export {
  i18nConfig
};