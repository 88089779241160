/**
 * Object for apiConfig
 *
 * @format
 * @property {string} base api url
 * @property {string} domain url domain
 */

const apiConfig = {
  domain: 'https://core.develop.fonixapis.com',
  importsUrl: 'https://dataimport.fonixapis.com/',
  apis: [{
      id: 'dashboard',
      url: 'https://dashboard.develop.fonixapis.com/'
    },
    {
      id: 'cctv',
      url: 'https://video.develop.fonixapis.com/'
    },
    {
      id: 'tacho',
      url: 'https://tacho.develop.fonixapis.com/'
    },
    {
      id: 'messaging',
      url: 'https://messages.develop.fonixapis.com/'
    },
    {
      id: 'pusher-auth',
      url: 'https://auth.develop.fonixapis.com/pusher/'
    },
    {
      id: 'forms',
      url: 'https://forms.develop.fonixapis.com/'
    }
  ],
  fonixAccount: 'https://develop.account.fonixtelematics.com',
  forms: 'https://forms.fonixtelematics.com',
};

apiConfig.url = `${apiConfig.domain}/`;

export {
  apiConfig
};
